const projects = [
  {
    title: "Intuitive Leap",
    imageSrc: "il.png",
    carouselItems: [
      {
        title: "Advanced AI and NLP Implementation",
        content: "Intuitive Leap represents a pinnacle in my career for its integration of cutting-edge AI and NLP technologies to automate IT support systems. I spearheaded the development of a sophisticated AI engine capable of understanding and processing user tickets, employing TensorFlow and BERT models for sentiment analysis and intent recognition. This involved extensive data preprocessing, training custom models, and fine-tuning algorithms to achieve unparalleled accuracy in automated response generation."
      },
      {
        title: "Seamless Integration and System Design",
        content: "The architecture of Intuitive Leap was designed for scalability and integration, utilizing .NET Core for its cross-platform capabilities and C# for its powerful backend logic. I architected a microservices infrastructure deployed on Microsoft Azure, ensuring high availability and low latency. The use of Docker containers and Kubernetes orchestration allowed for seamless CI/CD pipelines, significantly improving deployment efficiency and system reliability."
      },
      {
        title: "Data Analytics and Prediction Models",
        content: "A key feature of Intuitive Leap was its predictive analytics capability, designed to forecast IT support demands and optimize resource allocation. I implemented machine learning models using Python's scikit-learn and pandas for data analysis, creating predictive algorithms that analyzed historical ticket data to anticipate future trends. This proactive approach reduced downtime and improved IT department efficiency by 30%."
      }
    ]
  },
  {
    title: "Accuray",
    imageSrc: "Accuray.png",
    carouselItems: [
      {
        title: "Full-stack Web Development and Deployment",
        content: "The Accuray project showcased my full-stack development skills, as I built and hosted a Django-based website on AWS, integrating Django's secure and scalable framework with AWS's elastic computing capabilities for high traffic management. I implemented RESTful APIs for dynamic data interaction, leveraged AWS S3 for static and media file storage, and ensured data integrity and security through PostgreSQL, all while adhering to best practices for web security and compliance."
      },
      {
        title: "Data Engineering and Machine Learning",
        content: "My role in Accuray extended to data engineering, where I handled the acquisition, cleaning, and preprocessing of diverse datasets using Python libraries such as NLTK for natural language processing, Pandas for data manipulation, and NumPy for numerical data processing. I then built and fine-tuned multiple classifiers using scikit-learn to drive insights, achieving a top model accuracy of 90%. This process involved complex feature engineering, hyperparameter tuning, and cross-validation techniques to ensure robust model performance."
      },
      {
        title: "Classifier Development and Analytics",
        content: "Developing four distinct classifiers for Accuray involved intricate work with scikit-learn, focusing on models like Random Forest, SVM, and Gradient Boosting. The optimization of these models was based on a detailed analysis of precision, recall, and F1 scores, using grid search and k-fold cross-validation to fine-tune their performance. The successful classifier, with 90% accuracy, significantly enhanced our predictive capabilities, showcasing my technical proficiency in machine learning workflows."
      }
    ]
  },
  {
    title: "UCCS Research",
    imageSrc: "paper.png",
    carouselItems: [
      {
        title: "Innovative AI Research in Cognitive Psychology",
        content: "At UCCS, I led a groundbreaking research initiative that bridged AI and cognitive psychology, focusing on the phenomenon of catastrophic forgetting in neural networks. By applying psychological priming principles, I devised novel class ordering methodologies that significantly mitigated this issue. This involved extensive experimentation with TensorFlow and Keras, manipulating network architectures, and employing advanced techniques like Elastic Weight Consolidation (EWC) and Progressive Neural Networks (PNNs) to preserve learned knowledge across tasks."
      },
      {
        title: "Collaborative Publication and Methodology",
        content: "The research was a collaborative effort, culminating in a publication for IEEE ICSC 2023. My contribution was pivotal in the experimental design, data analysis using Python and MATLAB, and the application of statistical tests to validate our hypotheses. The methodology section of our paper detailed the innovative approaches we developed for applying priming effects within AI, setting a new precedent for interdisciplinary research in this field."
      },
      {
        title: "Implications for AI and Future Work",
        content: "Our research at UCCS not only provided immediate solutions to catastrophic forgetting but also opened new avenues for integrating cognitive principles into AI development. The implications extend to designing more resilient and adaptive learning systems, potential applications in robotics, and continual learning models. Future work involves expanding these concepts to larger neural networks and exploring their impact on reinforcement learning and unsupervised learning paradigms."
      }
    ]
  },
  {
    title: "Unifi",
    imageSrc: "unifi.png",
    carouselItems: [
      {
        title: "Web Development and Plugin Customization",
        content: "At Unifi, I led the technical development of cutting-edge web solutions, moving beyond WordPress to leverage the power of Django, React, and Svelte for building responsive and intuitive user interfaces. My work included custom plugin development for WordPress, enhancing functionality with PHP and JavaScript, and integrating external APIs for enriched content delivery. This role demanded a deep understanding of web architecture, front-end frameworks, and back-end programming, showcasing my versatility as a full-stack developer."
      },
      {
        title: "SEO Optimization and Analytics",
        content: "Optimizing websites for search engines was a critical aspect of my work at Unifi. I implemented advanced SEO strategies, including semantic HTML5, structured data markup, and server-side rendering with Node.js for improved crawlability. Utilizing Google Analytics and custom-built analytics with Python, I monitored user engagement and optimized content strategies, achieving a 40% increase in organic traffic."
      },
      {
        title: "Project Management and Agile Methodologies",
        content: "I spearheaded project planning and strategy at Unifi, adopting Agile methodologies to enhance team productivity and project transparency. Utilizing tools like Jira for task management and Confluence for documentation, I facilitated sprint planning, retrospectives, and daily stand-ups, ensuring projects were delivered on time and within budget. My strategic oversight and technical leadership were instrumental in navigating complex project requirements and achieving key deliverables."
      }
    ]
  }
];

export default projects;
