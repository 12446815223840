import React from 'react';
import ProjectCard from './card'; // Adjust the import path as necessary
import projects from './projects'; // Adjust the import path to where your data file is located

function Portfolio() {
  return (

    <div>
    <section className="section">
        <h3 className="section-title">Welcome to My Portfolio</h3>
        <div className="section-info">In this portfolio, you'll find a curated selection of my projects,
        showcasing my skills and accomplishments across various domains. Each project is meticulously documented
        with three detailed slides - highlighting the key features, technological stack, and future prospects or
        impacts of the work. Projects are presented in reverse chronological order, ensuring you see my latest and most
        advanced work first.</div>
      </section>
      {projects.map((project, index) => (
        <ProjectCard key={index} {...project} />
      ))}
    </div>
  );
}

export default Portfolio;
