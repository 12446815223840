import React from 'react';

const HomePage = () => {
  return (
    <div className="container">
      <div className="row align-items-center my-5">
        <div className="col-md-4">
          <img src={`${process.env.PUBLIC_URL}/profile.JPG`} alt="Profile" className="img-fluid profile-image rounded-circle" />
        </div>
        <div className="col-md-8">
          <h1 className="display-4">Hello, I'm Gabriel!</h1>
          <p className="lead">An AI programmer passionate about making data meaningful through AI.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <h2 className="card-title">About Me</h2>
              <p>As a Computer Science graduate with a deep passion for AI, I specialize in machine learning and deep learning.
               My journey through AI is distinguished by hands-on roles in research and development,
               allowing me to not only broaden my expertise but also drive innovation in the field.
               I have engaged in cutting-edge academic research, developing innovative algorithms,
               and have applied this knowledge as an AI developer to create practical solutions for real-world challenges.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <h2 className="card-title">Skills & Expertise</h2>
              <p>Skilled in Python, TensorFlow, and PyTorch, I bring a robust toolkit for developing advanced AI solutions. My project work ranges from predictive models to AI-driven applications, showcasing my ability to innovate and solve real-world problems.</p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <h2 className="card-title">Looking Forward</h2>
              <p>Eager to contribute my skills and passion to a team that values innovation and the transformative potential of AI. I'm looking for opportunities to leverage AI in enhancing operations, customer experiences, and solving challenging problems.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
