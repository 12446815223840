import React from 'react';

function Education() {
    return (
        <div className="section">
            <h3 className="section-title">Education</h3>
            <div className="section-info">Lewis & Clark College<br />
            Bachelor's degree, Computer Science and Rhetoric and Media Studies (2019 - 2023)</div>
        </div>
    );
}

export default Education;
