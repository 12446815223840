import React from 'react';
import { Carousel } from 'react-bootstrap';

const ProjectCard = ({ title, imageSrc, carouselItems }) => {
  return (
    <div className="section-info">
      <h3 className="section-title">{title}</h3>
      <div className="category">
        <div className="row">
          <div className="col-md-6">
            <img src={`${process.env.PUBLIC_URL}/${imageSrc}`} className="img-fluid fit-width" alt={title} />
          </div>
          <div className="col-md-6">
            <Carousel prevLabel="" nextLabel="" indicators={false} interval={null}>
              {carouselItems.map((item, index) => (
                <Carousel.Item key={index}>
                  <div>
                    <h4>{item.title}</h4>
                    <p>{item.content}</p>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
