const experiences = [
  {
    title: "AI Consultant",
    company: "Intuitive-Leap",
    dates: "July 2023 - Present",
    location: "Portland, Oregon Metropolitan Area",
    details: [
      "Strategically advised on Azure database architectures, driving enhanced performance and scalability.",
      "Expertly debugged and optimized diverse codebases, ensuring seamless deployment and robust application performance.",
      "Executed comprehensive penetration testing, fortifying systems against advanced security threats.",
      "Spearheaded the development of a cutting-edge C# web application, setting new standards in user engagement and functionality."
    ]
  },
  {
  title: "AI Intern",
  company: "Accuray",
  dates: "August 2022 - May 2023",
  location: "Portland, Oregon Metropolitan Area",
  details: [
    "Built and hosted a website with Django on AWS, showcasing robust deployment and management skills.",
    "Gathered, cleaned, and preprocessed multiple datasets using NLTK, Pandas, and sklearn, demonstrating strong data engineering capabilities.",
    "Developed and fine-tuned 4 classifiers using sklearn, achieving top performance with a 90% accuracy rate, reflecting advanced machine learning proficiency.",
    "Led a team of 4 developers, utilizing Scrum Agile methodologies to enhance project efficiency and team collaboration."
  ]
},
  {
    title: "CIO Support",
    company: "Lewis & Clark College",
    dates: "November 2019 - May 2023",
    location: "Portland, Oregon Metropolitan Area",
    details: [
      "Pioneered the development of an Alexa skill, mastering Node.js, Git, GitHub, and Alexa ASK through self-directed learning.",
      "Acted as the principal technical consultant for Amazon’s Alexa Skill Pilot Program, delivering critical insights and direction.",
      "Developed a comprehensive proof of concept for key stakeholders, demonstrating innovative applications of voice technology in education."
    ]
  },
  {
    title: "AI Researcher",
    company: "UCCS",
    dates: "May 2022 - January 2023",
    location: "Colorado Springs, Colorado, United States",
    details: [
      "Gained expertise in an array of machine learning and natural language processing technologies, contributing to cutting-edge research projects.",
      "Engaged with the latest NLP research, providing foundational insights for experimental strategies and innovations.",
      "Collaborated closely with research advisors to present findings, leading to significant advancements in NLP methodologies.",
      "Authored a seminal paper on data ordering for NLP, offering groundbreaking insights into efficient, generalizable learning heuristics."
    ]
  },
  {
    title: "Section Editor",
    company: "The Pioneer Log",
    dates: "January 2020 - January 2023",
    location: "Portland, Oregon, United States",
    details: [
      "Led the Sports and Opinions sections, driving content strategy and enhancing the newspaper’s editorial quality.",
      "Orchestrated the ideation and execution of compelling stories, fostering a culture of creativity and journalistic excellence.",
      "Championed the adoption of AP style, elevating the publication’s professionalism and readability."
    ]
  },
  {
    title: "Teaching Assistant",
    company: "Lewis & Clark College",
    dates: "August 2022 - December 2022",
    location: "Portland, Oregon, United States",
    details: [
      "Conducted engaging instructional sessions, significantly enhancing student understanding of complex algorithms.",
      "Provided invaluable one-on-one support during office hours, greatly improving student academic performance.",
      "Assessed and graded a wide range of projects, ensuring fair and constructive feedback."
    ]
  },
  {
    title: "Web dev Intern",
    company: "Unifi",
    dates: "June 2021 - August 2021",
    location: "California, United States",
    details: [
      "Revolutionized user interface design, significantly enhancing accessibility and user satisfaction.",
      "Conducted in-depth technology research, advocating for the adoption of modern frameworks to streamline development processes.",
      "Led a dedicated virtual team in the agile development and timely deployment of critical web projects."
    ]
  },
  {
  title: "Copywriter Intern",
  company: "UGA, Public Service and Outreach Office",
  dates: "August 2018 - April 2019",
  location: "Athens, Georgia, United States",
  details: [
    "Transcribed interviews lasting upwards of an hour, showcasing attention to detail and patience.",
    "Organized and labeled extensive photo galleries, demonstrating strong organizational skills.",
    "Conducted interviews over the phone and in person, highlighting effective communication and interpersonal skills.",
    "Wrote and copy-edited articles, refining my abilities in written communication and attention to detail."
  ],
},
{
  title: "Lab Intern",
  company: "UGA, Dr. Van Der Knaap’s Genomics Lab",
  dates: "May 2017 - August 2017",
  location: "Athens, Georgia, United States",
  details: [
    "Acquired proficiency in Microsoft Excel, enhancing my data analysis and management capabilities.",
    "Learned kinesthetically lab procedures and basic genomics, gaining hands-on experience in a research setting.",
    "Generated and manipulated data, applying critical thinking and analytical skills.",
    "Drew sound scientific conclusions based on data analysis, demonstrating the ability to synthesize information and formulate insights."
  ],
}


];

export default  experiences;
