import React from 'react';

function Summary() {
    return (
        <div className="section">
            <h3 className="section-title">Summary</h3>
            <div className="section-info">
            <p>Goal-oriented machine learning researcher and engineer with over four years of specialized experience in natural language processing (NLP).
             Adept at designing, developing, and implementing advanced NLP models and algorithms that improve language understanding
             and information retrieval systems. Proven track record in leading projects from conception to deployment,
             consistently delivering solutions that enhance data analytics capabilities and drive business insights.
             Recognized for a strong blend of research acumen and practical engineering skills, with a commitment to pioneering
             innovative AI solutions that address complex challenges. Excellent collaborator with cross-functional teams, aiming to
             leverage AI to unlock new opportunities and elevate organizational performance. Eager to apply my expertise in a dynamic
             new role that fosters growth, innovation, and impactful outcomes in the field of artificial intelligence.</p>
            </div>
        </div>
    );
}

export default Summary;
