import React from 'react';
import './SectionStyles.css'; // Import the CSS stylesheet

function Publications() {
  return (
    <div className="section">
      <h3 className="section-title">Publications</h3>
      <div className="section-info">
        <h4>Utilizing Priming to Identify Optimal Class Ordering to Alleviate Catastrophic Forgetting</h4>
        <p>
          Authors: Gabriel Mantione-Holmes, Justin Leo, Jugal Kalita<br />
          Submitted on: 24 Dec 2022<br />
          Accepted to: IEEE International Conference on Semantic Computing (ICSC) 2023<br />
        </p>
      </div>
    </div>
  );
}

export default Publications;
